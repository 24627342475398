:root {
  --primary-color: #BE72E7;
  --tertiary-color: #edbdff;
  --text-color: #1D1D1B;
  --hover-background-color: rgba(255, 255, 255, 0.4);
  --toggle-size-scale: 3; /* You can increase this value to scale the toggle */
  --secondary-color: #F8F8FA;

}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: var(--secondary-color);
}

/* North pointer */
.north-pointer {
  position: absolute;
  top: calc(47 / var(--layout-height) * 100vh);
  left: calc(56 / var(--layout-width) * 100vw);
  width: calc(118 / var(--layout-width) * 100vw);
  height: calc(123 / var(--layout-height) * 100vh);
  z-index: 11;
}

/* Slider menu */
.slider-menu {
  position: absolute;
  top: 0;
  width: 320px;
  height: 100vh;
  left: calc(100vw - 320px);
  opacity: 0.9;
  background: var(--secondary-color);
  transition: right 0.3s ease-in-out;
  z-index: 11;
  display: flex;
  flex-direction: column;
}

.slider-menu.open {
  width: 320px;
}

/* FLEX BASE */
.container-logo {
  flex: 15;
  opacity: 1.0;
}

.container-type {
  flex: 10;
}
.container-price {
  flex: 15;
}
.container-features {
  flex: 15;
}
.container-status {
  flex: 20;
}



/* Logo */
.logo {
  margin: 5% 15% 0 15%;
  width: 70%;
  height: 80%;
}

/* TYPE */

.container-type span {
  font-size: 1.1em;
  font-weight: bold;
  display: inline-block;
  color: #333;
  margin: 5% 0 0 10%;
}

.select-type-buttons {
  display: flex;
  margin: 6% 10% 3% 10%;
  width: 80%;
  height: 40%;
}

.select-type-button {
  flex: 1;
  font-size: 1em;
  font-weight: bold;
  border: 2px solid var(--tertiary-color);
  background-color: var(--secondary-color);
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 0;
}

.select-type-button:first-child {
  border-radius: 8px 0 0 8px;
}

.select-type-button:last-child {
  border-radius: 0 8px 8px 0 ;
}

.select-type-button:hover {
  background-color: var(--tertiary-color);
  color: white;
}

.select-type-button.active {
  background-color: var(--primary-color);
  color: white;
}

/* PRICE */


.container-price span {
  font-size: 1.1em;
  font-weight: bold;
  display: inline-block;
  color: #333;
  margin: 5% 0 0 10%;
}

.container-price-text {
  display: flex;
  align-items: center; /* Aligns the spans vertically in the center */
  width: 100%;
}

.container-price-min,
.container-price-mid,
.container-price-max {
  flex-grow: 0; /* Prevents the spans from growing unnecessarily */
  flex-basis: auto; /* Makes each span take up only as much space as its content */
  text-align: left;
  font-size: 1.4em; /* Increased font size */
  font-weight: 600; /* Makes the font slightly darker (use 700 for full bold) */
  color: #333; /* Optional: A darker color for better readability */
}

.range-slider {
  position: relative;
  width: 80%; /* The slider takes 80% of the parent width */
  margin: 5% 10% 0 10%; /* 10% margin on the left and right */
  height: 8px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%; /* Full width of the 80% container */
  height: 8px;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px; /* Same unit for both width and height for proportionality */
  height: 24px; /* Proportional to width */
  border-radius: 50%;
  transform: translate(-2px, -2px);
  background: var(--secondary-color);
  border: 0.1vw solid var(--tertiary-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
}

.slider::-moz-range-thumb {
  width: 24px; /* Same unit for both width and height for proportionality */
  height: 24px; /* Proportional to width */
  border-radius: 50%;
  background: var(--secondary-color);
  border: 0.1vw solid var(--tertiary-color);
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.range-slider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%; /* Full width of the slider */
  height: 8px;
  background: linear-gradient(
          to right,
          #be72e7 calc((var(--minValue) / 3150000) * 100%),
          #2b2b2b calc((var(--minValue) / 3150000) * 100%),
          #be72e7 calc((var(--maxValue) / 3150000) * 100%),
          var(--tertiary-color) calc((var(--maxValue) / 3150000) * 100%)
  );
  border-radius: 10px;
  transform: translateY(-50%);
  z-index: 1;
}


.range-values {
  display: flex;
  width: 80%;
  margin: 8% 10% 3% 10%;
  padding: 0;
}

.range-values span {
  font-size: 1em;
  color: #ABAAA9;
  font-weight: bold;
  flex: 1;
  margin: 0;
}

.range-values-left {
  text-align: left; /* Align left */
}

.range-values-right {
  text-align: right; /* Align right */
}

/* FEATURES */
.toggle-parent {
  width: 80%;
  margin: 2% auto;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between each toggle container */
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 15px; /* Fixed gap between the switch and the label */
}

.switch {
  position: relative;
  width: 50px; /* Fixed width of the toggle */
  height: 25px; /* Fixed height of the toggle */
  background-color: #f8f8fa;
  border: 2px solid #be72e7;
  border-radius: 25px; /* Half of the height to make it circular */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch::after {
  content: '';
  position: absolute;
  top: 3px; /* Keeps the knob centered vertically */
  left: 3px; /* Keeps the knob aligned to the left */
  width: 19px; /* Fixed width of the knob */
  height: 19px; /* Fixed height of the knob */
  background-color: #be72e7;
  border-radius: 50%; /* Circular knob */
  transition: left 0.3s ease, background-color 0.3s ease;
}

.switch.active {
  background-color: var(--primary-color);
}

.switch.active::after {
  left: calc(100% - 22px); /* Adjust the knob's active position */
  background-color: var(--secondary-color);; /* Change knob color when active */
}
.container-features-label {
  font-size: 1em; /* Fixed font size for better consistency */
  color: #333;
  flex-grow: 1; /* Ensures the label fills remaining space */
  text-align: left;
}




/* STATUS */

.status-title {
  font-size: 1.2em; /* Fixed size for consistency */
  font-weight: bold;
  color: #333;
  margin-bottom: 15px; /* Adjusted to have a simpler fixed size */
  margin-left: 10%;
}

/* Buttons */
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  width: 80%; /* 10% margin on each side */
  margin: 0 auto; /* Center the container within the parent */
}

.inline-button {
  width: 100%; /* Take full width of the button container */
  max-width: 250px; /* Ensure the button has a max width */
  height: 56px; /* Fixed height for the button */
  border: 2px solid var(--tertiary-color);;
  background-color: #f8f8fa;
  color: #be72e7;
  font-size: 1em; /* Fixed font size */
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.inline-button.active {
  background-color: #be72e7;
  color: white;
}

.inline-button:hover {
  background-color: var(--tertiary-color);
  color: #be72e7;
}

.inline-button-text {
  width: 100%; /* Take full width of the container */
  max-width: 250px; /* Ensure the button text container has a max width */
  height: 56px; /* Fixed height */
  border: none;
  background: transparent;
  color: #be72e7;
  font-size: 1em; /* Fixed font size */
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.inline-button-text:hover {
  color: #be72e7;
  text-decoration: underline;
}
