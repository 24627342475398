:root {
    --layout-width: 1920;
    --layout-height: 1080;
    --primary-color: #be72e7;
}

/* Popup Frame */
.popup-frame {
    position: fixed;
    width: 256px; /* Scaled width */
    aspect-ratio: 0.5;
    right: 330px; /* Scaled right position */
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    background: rgba(204, 105, 76, 0.8);
    border-radius: calc(15 / var(--layout-width) * 100vw); /* Scaled border-radius */
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

/* Popup Header */
.popup-header {
    flex: 10;
}

/* Close Button */
.popup-close {
    position: absolute;
    width: calc(32 / var(--layout-width) * 100vw); /* Scaled width */
    aspect-ratio: 1;
    right: calc(6 / var(--layout-width) * 100vw); /* Scaled right position */
    top: calc(16 / var(--layout-height) * 100vh); /* Scaled top position */
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

/* Popup Image Container */
.popup-image {
    border-radius: calc(15 / var(--layout-width) * 100vw); /* Scaled border-radius */
    flex: 270;
    background: #cccccc;
    margin-left: calc(10 / var(--layout-width) * 100vw); /* Scaled margins */
    margin-right: calc(10 / var(--layout-width) * 100vw); /* Scaled margins */
}

.popup-image img {
    width: 100%;
    height: 100%;
    border-radius: calc(15 / var(--layout-width) * 100vw); /* Scaled border-radius */
}

/* Popup Content */
.popup-content {
    flex: 232;
    padding: calc(10 / var(--layout-width) * 100vw); /* Scaled padding */
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
}

/* Popup Heading */
.popup-content h3 {
    margin: calc(10 / var(--layout-height) * 100vh) 0;
    font-size: calc(1.5em * (100vw / var(--layout-width))); /* Scaled font size */
    flex: 3;
}

/* Popup Paragraph */
.popup-content p {
    flex: 2;
    margin: calc(5 / var(--layout-height) * 100vh) 0;
    font-size: calc(1.2em * (100vw / var(--layout-width))); /* Scaled font size */
    text-align: left;
}

/* Popup Button */
.popup-content button {
    flex: 3;
    margin: calc(5 / var(--layout-height) * 100vh) 0;
    font-size: calc(1.2em * (100vw / var(--layout-width))); /* Scaled font size */
    border: calc(1 / var(--layout-width) * 100vw) solid var(--primary-color); /* Scaled border */
    cursor: pointer;
    background: white;
    color: var(--primary-color);
    border-radius: calc(5 / var(--layout-width) * 100vw); /* Scaled border-radius */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
