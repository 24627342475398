body {
    margin: 0;
    background-color: black;
    position: center;
    user-modify: none;
}


div.FrameElemDiv {
    background-color: #FDF2D6;
    #-moz-user-select: none;
    #-webkit-user-select: none;
    #user-select: none;

}



img.BackgroundFrameImg {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;

    display: block;
    background-color: white;
    user-select: none;
    object-fit: cover;
}

div.ThreeOverlay {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
}
